<template>
    <div class="UserAnaly_A">
        <div class="rana_con">
            <div class="rana_con_title">
                你觉得这些用户人群中谁是你的目标用户？（在下列标签中选择一个）
            </div>
            <!-- 内容 -->
            <div class="rana_con_content">
                <div class="arrow_box">
                    <div class="arrow_box_left">
                        <img src="./../../../assets/img/arrow_left.png" alt="" @click="prevBox()" v-show="this.currentpage>1">
                    </div>
                    <ul class="arrow_box_cente">
                        <li v-for="(item,index) in caseList" :key="index" :class="item.val==1?'li_up':'li_down'" @click="caseBox(item,index)">
                            <div class="arr_item_img">
                                <img v-if="item.val==1" src="./../../../assets/img/icon_white.png" alt="">
                                <img v-else src="./../../../assets/img/icon_blue.png" alt="">
                            </div>
                            <div class="arr_item_name">
                                <span>姓名：{{item.name}}</span>
                            </div>
                            <div class="arr_item_name">
                                <span>性别：{{item.sex}}</span>
                            </div>
                            <div class="arr_item_name">
                                <span>年龄：{{item.age}}</span>
                            </div>
                            <img class="line_img" src="./../../../assets/img/line.png" alt="">
                            <div class="line_cont" :title="item.desc1">
                                <span>
                                    {{item.desc1}}
                                </span>
                            </div>
                        </li>
                    </ul>
                    <div class="arrow_box_left">
                        <img src="./../../../assets/img/arrow_right.png" alt="" @click="nexBox()" v-show="this.currentpage * 5 < this.totalnum">
                    </div>
                </div>
            </div>
            <!-- 按钮 -->
            <div class="butt_div">
                <div class="button_one" v-preventReClick @click="prevstep()">
                    <span>取消</span>
                </div>
                <div class="button_two" v-preventReClick @click="nextBotn()">
                    <span>确定</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            istrue:-1,
            caseList:[],
            currentpage:1,
            totalnum:0
        };
    },
    methods: {
        getapp(){
            this.axios.projectS1P1({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    var str = res.data.data.qrcode
                    this.$emit('info', str);
                    this.array = res.data.data.values
                    this.totalnum = res.data.data.values.length
                    const newarray = []
                    const allpage = Math.ceil(res.data.data.values.length / 5)
                    for (var i = 0; i < allpage; i++) {
                        newarray.push(res.data.data.values.slice(i * 5, i * 5 + 5))
                    }
                    newarray.forEach((el,index)=>{
                        el.forEach((els,indexs)=>{
                            if(els.val==1){
                                this.currentpage = index +1
                                // this.istrue = indexs
                            }
                        })
                    })
                    this.newarrays = newarray
                    this.caseList = this.newarrays[this.currentpage - 1]
                    if(this.caseList==undefined){
                        this.caseList = this.newarrays[this.currentpage - 2];
                    }
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{
                
            })
        },
        caseBox(item,val){
            this.newarrays.forEach((el,index)=>{
                el.forEach(els=>{
                    if(els.id == item.id){
                        els.val = 1
                    }else{
                        els.val = 0
                    }
                })
              })
        },
        prevstep(){
            this.$router.push({name:'StudentPage'})
        },
        // 上一页
        prevBox(){
            if(this.currentpage>1){
                this.currentpage = this.currentpage - 1
                this.caseList = this.newarrays[this.currentpage - 1]
            }else{
                this.currentpage = 1
            }
        },
        //下一页
        nexBox(){
            if(this.currentpage * 5 < this.totalnum){
                this.currentpage = this.currentpage + 1
                this.caseList = this.newarrays[this.currentpage - 1]
            }else{
                
            }
        },
        nextBotn(){
            let array = this.newarrays
            var arr = []
            for(var i in array){
               arr = arr.concat(array[i])
            }
            // arr.map((item,index)=>{
            //     if(this.currentpage>1){
            //         if((this.currentpage - 1) * 5 + this.istrue == index){
            //             item.val = 1
            //         }else{
            //             item.val = 0
            //         }
            //     }else{
            //         if(this.istrue == index){
            //             item.val = 1
            //         }else{
            //             item.val = 0
            //         }
            //     }   
            // })
            this.axios.Sets1({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
                node:'p1',
                value:arr,
            }).then(res=>{
                if(res.data.code==0){
                    this.$router.push({name:'UserAnaly_B'})
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{
                console.log(err);
            })
        }
    },
    mounted() {
        this.getapp()
    }
};
</script>
<style scoped>
@import './../../../assets/css/student/A/UserAnaly_A.css';
</style>